<template>
	<div>
		<v-overlay v-model="dialogVisibility" color="#0E82B9" opacity="0.92">
			<v-container>
				<v-row class="pa-10 text-center">
					<div v-if="error" class="col-sm-1 col-md-3"></div>
					<div v-if="error" class="col-sm-10 col-md-6">
						<v-alert class="mt-5 red" border="left" text>
							{{ errorMsg }}
						</v-alert>
					</div>
					<div class="col-12 white--text">
						{{ $t("please_tell_us_your_ID_to_sign.", { id: typeOfId }) }}
					</div>
					<div class="offset-md-4 col-md-4 col-sm-12">
						<input type="text" id="custom-input" v-model="citizenId" :placeholder="$t('_id')"
							@input="citizenId = $event.target.value.toUpperCase()" class="txt-uppercase" v-uppercase />
					</div>
					<div class="col-12">
						<v-btn color="#F36E21" @click="confirm()" :disabled="!citizenId" depressed class="mt-3" min-width="96">
							{{ $t("ok") }}
						</v-btn>
					</div>
				</v-row>
			</v-container>
		</v-overlay>
	</div>
</template>

<script>
import { SignaturesService } from "../../services/signatures-service";


export default {
	name: "access-modal",

	props: ["dialog", "documentId", "documentToken", "typeOfId"],

	data: function () {
		return {
			citizenId: null,
			error: false,
			errorMsg: "",
			loading: false,
			dialogVisibility: false
		};
	},

	watch: {
		dialog() {
			this.dialogVisibility = this.dialog
		}
	},

	computed: {
		requestId() {
			return this.isAuthCitizenId ? this.$store.state.common.requestId : this.documentId;
		},
		isAuthCitizenId() {
			return this.$route.query.auth && this.$route.query.auth == 'citizenid' ? true : false;
		},
		hasAssociation() { //to check if the url comes with the company name
			return this.$store.getters['common/hasAssociation']
		},
	},

	methods: {
		confirm() {
			this.error = false;
			this.errorMsg = "";

			if (this.citizenId) {
				SignaturesService.getSignatureRequestDataAuth(
					this.requestId,
					this.citizenId,
					this.isAuthCitizenId,
					this.documentToken
				)
					.then((response) => {
						this.error = false;
						this.loading = false;

						if (
							response.data.signerId &&
							response.data.signerId.id == this.citizenId
						) {
							this.$store
								.dispatch("common/addAuthId", this.citizenId)
								.then((response) => {
									if (response) {
										if (this.isAuthCitizenId) {
											this.$emit("closeModal");
										} else {
											this.checkForNextStep();
										}
									}
								});
						} else {
							this.error = true;
							this.errorMsg = this.$t("invalid_auth_id");
						}
					})
					.catch((error) => {
						this.loading = false;
						if (error.response.status == 403) {
							this.error = true;
							this.errorMsg = error.response.data.description;
						}
					});
			}
		},
		async checkForNextStep() {
			let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];

			documentCheckList.forEach((document, index) => {
				documentCheckList[index].checkId = false;
				documentCheckList[index].idChecked = true;
			});

			localStorage.setItem('documentCheckList', JSON.stringify(documentCheckList));

			for (const document of documentCheckList) {
				if (document.checkAttachments && !document.attachmentsChecked) {
					this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}attachments`, params: { id: document.documentId }, query: this.$route.query })
					break;
				}
			}
		},

	},
};
</script>
