<template>
	<div>
		<div class="footer-message">
			<v-container class="py-0 fill-height">
				<v-row>
					<div class="col-md-9 col-xs-12 text-left terms">
						<v-list three-line>
							<v-list-item>
								<template v-slot:default="{ active }">
									<v-list-item-action left style="vertical-align: text-top">
										<v-checkbox @change="changeAgrement()" dark color="#F36E21" v-model="terms_accepted"
											:input-value="active"></v-checkbox>
									</v-list-item-action>

									<v-list-item-content class="terms-conditions-text">
										{{ $t("terms_conditions") }}
										<a href="https://www.portasigma.com/politica-privacidad/" target="_blank" class="white--text"
											rel="noopener">{{ $t("privacy_policy") }}</a>
									</v-list-item-content>
								</template>
							</v-list-item>
						</v-list>
					</div>
					<div class="col-md-3 col-xs-12 see-document mt-md-5 mt-lg-5">
						<v-btn x-large :disabled="!agreed" block color="#F36E21" dark @click="handleButtonClick()">
							{{ $t("signer_form.next_button") }}
							<!-- {{  buttonText }} -->
						</v-btn>
					</div>
				</v-row>
			</v-container>
		</div>
		<access-modal :dialog="accessDialog" @closeModal="closeAccessModal" :typeOfId="typeOfId"></access-modal>
	</div>
</template>

<script>
import AccessModal from "./AccessModal.component";
import { mapState, mapGetters } from 'vuex';
import FormsService from '../../services/forms-service.js';
import UploadService from "../../services/upload-service.js";

export default {
	components: {
		AccessModal
	},
	props: {
		isSigned: {
			type: Boolean,
			required: true
		},
		Token: {
			type: String,
			required: true
		},
		typeOfId: {
            type: String,
            required: true
        }
	},
	data() {
		return {
			terms_accepted: null,
			agreed: false,
			authCitizenId: null,
			accessDialog: false,
			AuthID: null,
			token: null,
			loading: false
		};
	},
	mounted() {
		this.authCitizenId = this.$route.query.auth || null;
	},
	computed: {
		auth() {
			return this.$store.state.common.auth;
		},
		status() {
			return this.$store.state.common.status;
		},
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		},
		buttonText() {
			if (this.hasAttachments) {
				return 'See Attachments';
			}
			if (this.hasSignerForm && this.hasPendingSignerForm) {
				return this.$t("see_form");
			}
			return this.$t("see_document") + this.hasPendingSignerForm;
		},
		...mapState({
			SignRequestID: state => state.common.requestId,
		}),
		...mapGetters('attachments', [
			'hasAttachments', 'getTransactionApiToken'
		]),
		hasSignerForm() {
			return this.$store.getters['forms/hasSignerForm'];
		},
		hasPendingSignerForm() {
			return this.$store.getters['forms/hasPendingSignerForm'];
		}

	},
	methods: {
		changeAgrement() {
			this.agreed = !this.agreed;
		},
		authentication() {
			if (this.authCitizenId === "citizenid") {
				this.authPopUp();
			} else {
				this.closeAccessModal();
			}
		},
		pdfView() {
			this.authentication();
		},
		closeModal() {
			this.agreed = false;
			this.terms_accepted = null;
			this.authentication();
		},
		authPopUp() {
			this.accessDialog = true;
		},
		async closeAccessModal() {
			this.accessDialog = false;

			this.AuthID = localStorage.getItem("auth_id");
			await this.loadDocumentData(this.SignRequestID, this.AuthID);


			let docCount = await this.getUploadedDocCount(this.SignRequestID, this.Token, this.AuthID);
			docCount = Number(docCount);

			console.log(docCount);

			if (isNaN(docCount)) {
				console.error('docCount is not a number:', docCount);
				docCount = 0;  // Set default to 0 if NaN
			}
			console.log('status = ' + this.hasAttachments + this.hasSignerForm + this.hasPendingSignerForm);

			if (this.hasAttachments && docCount == 0  && this.hasPendingSignerForm && this.hasSignerForm) {
				this.$router.push({
					name: `${this.hasAssociation ? 'custom-' : ''}attachments`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});
			} else if (this.hasAttachments && (docCount > 0) && this.hasPendingSignerForm && this.hasSignerForm) {
				this.$router.push({
					name: `${this.hasAssociation ? 'custom-' : ''}forms`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});
			} else if (!this.hasAttachments && this.hasPendingSignerForm && this.hasSignerForm) {
				this.$router.push({
					name: `${this.hasAssociation ? 'custom-' : ''}forms`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});
			
			}  else if (this.hasAttachments && (docCount > 0)  && !this.hasPendingSignerForm && this.hasSignerForm) {
				this.$router.push({
					name: `${this.hasAssociation ? 'custom-' : ''}pdf-viewer`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});
			} else if (!this.hasAttachments && !this.hasPendingSignerForm && !this.hasSignerForm) {
				this.$router.push({
					name: `${this.hasAssociation ? 'custom-' : ''}pdf-viewer`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});
			} else if (this.hasAttachments && !this.hasPendingSignerForm && !this.hasSignerForm) {
				this.$router.push({
					name: `${this.hasAssociation ? 'custom-' : ''}attachments`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});
			} else if (this.hasAttachments && !this.hasPendingSignerForm) {
				this.$router.push({
					name: `${this.hasAssociation ? 'custom-' : ''}pdf-viewer`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});
			}


		},
		signerFormView() {
			this.authentication();
		},
		handleButtonClick() {

			if (this.hasAttachments) {
				this.signerFormView();
			} else if (this.hasSignerForm && this.hasPendingSignerForm) {
				this.signerFormView();
			}
			this.pdfView();

		},
		async loadDocumentData(RequestID, AuthenticationID) {
			if (this.SignRequestID) {
				this.loading = true;
				try {
					const response = await FormsService.getFormStatus(RequestID, this.Token, AuthenticationID);
					this.requestDetails = response.data;
					this.$store.commit('forms/setHasSignerForm', this.requestDetails.hasSignerForm);
					this.$store.commit('forms/setHasPendingSignerForm', this.requestDetails.hasPendingSignerForm);
					this.$store.commit('attachments/setHasAttachments', this.requestDetails.hasAttachments);
					await this.$store.dispatch('attachments/setTransactionApiToken', this.requestDetails.apiToken);

					this.loading = false;
				} catch (error) {
					console.error('API Call Failed:', error);
					this.loading = false;
				}
			} else {
				console.log('No Request ID found');
			}
		},

		async getUploadedDocCount($request_id, $request_token, $request_auth_id) {
			try {
				const attachments = await UploadService.getAttachments($request_id, $request_token, $request_auth_id);
				return attachments.data.length;
			} catch (error) {
				console.error('Error in getUploadedDocCount:', error);
				return 0;  // Return default value in case of error
			}
		},

	},
	async created() {

	}
};
</script>
